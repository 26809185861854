import React from "react";
import { useMemo } from 'react';
import Icon from '.';
import { gray } from '../../definitions/colors';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export default function Arrow(props) {
  const finalProps = useMemo(() => {
    const defaultProps = {
      width: 12,
      height: 7.4,
      color: gray[400],
      extraCSS: {}
    };
    return {
      ...defaultProps,
      ...props
    };
  }, [props]);
  return _jsx(Icon, {
    ...finalProps,
    children: _jsx("g", {
      transform: "matrix(1,0,0,1,-6,-8)",
      children: _jsx("path", {
        d: "M12,8L6,14L7.41,15.41L12,10.83L16.59,15.41L18,14L12,8Z"
      })
    })
  });
}