import React from 'react';
import MesaController from './MesaController';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
function Mesa(props) {
  const {
    state,
    children
  } = props;
  return _jsx(MesaController, {
    ...state,
    children: children
  });
}
export default Mesa;