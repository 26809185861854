import * as React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SvgLoading = props => _jsxs("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  height: "1em",
  viewBox: "0 0 24 24",
  width: "1em",
  ...props,
  children: [_jsx("path", {
    d: "M0 0h24v24H0V0z",
    fill: "none"
  }), _jsx("path", {
    d: "m19 8-4 4h3c0 3.31-2.69 6-6 6a5.87 5.87 0 0 1-2.8-.7l-1.46 1.46A7.93 7.93 0 0 0 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46A7.93 7.93 0 0 0 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z"
  })]
});
export default SvgLoading;