import React from 'react';
import Icon from './Icon';
import AnchoredTooltip from './AnchoredTooltip';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
class HelpTrigger extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      props
    } = this;
    const content = props.children;
    const className = 'Trigger HelpTrigger' + (props.className ? ' ' + props.className : '');
    const children = _jsx(Icon, {
      fa: "question-circle"
    });
    const newProps = {
      ...props,
      content,
      children,
      className
    };
    return _jsx(AnchoredTooltip, {
      ...newProps
    });
  }
}
export default HelpTrigger;