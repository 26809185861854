import React from 'react';
import Checkbox from '../Components/Checkbox';
import AnchoredTooltip from '../Components/AnchoredTooltip';
import Icon from '../Components/Icon';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
class SelectionCell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.selectAllRows = this.selectAllRows.bind(this);
    this.deselectAllRows = this.deselectAllRows.bind(this);
    this.renderPageCheckbox = this.renderPageCheckbox.bind(this);
    this.renderRowCheckbox = this.renderRowCheckbox.bind(this);
  }
  selectAllRows() {
    const {
      rows,
      options,
      eventHandlers
    } = this.props;
    const {
      isRowSelected
    } = options;
    const {
      onRowSelect,
      onMultipleRowSelect
    } = eventHandlers;
    const unselectedRows = rows.filter(row => !isRowSelected(row));
    if (onMultipleRowSelect) return onMultipleRowSelect(unselectedRows);
    return unselectedRows.forEach(onRowSelect);
  }
  deselectAllRows() {
    const {
      rows,
      options,
      eventHandlers
    } = this.props;
    const {
      isRowSelected
    } = options;
    const {
      onRowDeselect,
      onMultipleRowDeselect
    } = eventHandlers;
    const selection = rows.filter(isRowSelected);
    if (onMultipleRowDeselect) return onMultipleRowDeselect(selection);
    return selection.forEach(onRowDeselect);
  }
  renderPageCheckbox() {
    const {
      rows,
      isRowSelected,
      inert,
      options
    } = this.props;
    const selection = rows.filter(isRowSelected);
    const checked = rows.length && rows.every(isRowSelected);
    const isIndeterminate = selection.length > 0 && !checked;
    const {
      selectColumnHeadingDetails
    } = options;
    let handler = e => {
      e.stopPropagation();
      return checked || isIndeterminate ? this.deselectAllRows() : this.selectAllRows();
    };
    return _jsxs("th", {
      className: 'SelectionCell' + (selectColumnHeadingDetails ? '__withDetails' : ''),
      onClick: handler,
      children: [inert ? null : _jsx(AnchoredTooltip, {
        content: checked || isIndeterminate ? 'Clear all' : 'Select all',
        children: _jsx(Checkbox, {
          checked: checked,
          indeterminate: isIndeterminate
        })
      }), selectColumnHeadingDetails && _jsx("span", {
        children: selectColumnHeadingDetails.heading
      }), selectColumnHeadingDetails && selectColumnHeadingDetails.helpText && _jsx(AnchoredTooltip, {
        className: "Trigger HelpTrigger",
        content: selectColumnHeadingDetails.helpText,
        children: _jsx(Icon, {
          fa: "question-circle"
        })
      })]
    });
  }
  renderRowCheckbox() {
    const {
      row,
      isRowSelected,
      eventHandlers,
      inert
    } = this.props;
    const {
      onRowSelect,
      onRowDeselect
    } = eventHandlers;
    const checked = isRowSelected(row);
    let handler = e => {
      e.stopPropagation();
      return checked ? onRowDeselect(row) : onRowSelect(row);
    };
    return _jsx("td", {
      className: "SelectionCell",
      onClick: handler,
      children: inert ? null : _jsx(Checkbox, {
        checked: checked
      })
    });
  }
  render() {
    let {
      heading
    } = this.props;
    return heading ? this.renderPageCheckbox() : this.renderRowCheckbox();
  }
}
export default SelectionCell;