import React from "react";
import { Add } from '@material-ui/icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Plus = props => {
  const {
    height = '1em',
    width = '1em'
  } = props;
  return _jsx("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 100 100",
    height: height,
    width: width,
    ...props,
    children: _jsx(Add, {})
  });
};
export default Plus;