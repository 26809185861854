import React from 'react';
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import TableToolbar from './TableToolbar';
import ActionToolbar from './ActionToolbar';
import PaginationMenu from './PaginationMenu';
import EmptyState from './EmptyState';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
class MesaController extends React.Component {
  constructor(props) {
    super(props);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.renderActionBar = this.renderActionBar.bind(this);
    this.renderEmptyState = this.renderEmptyState.bind(this);
    this.renderPaginationMenu = this.renderPaginationMenu.bind(this);
  }
  renderPaginationMenu() {
    const {
      uiState,
      eventHandlers
    } = this.props;
    if (!uiState || !eventHandlers || !uiState.pagination || !eventHandlers.onPageChange) return null;
    return _jsx(PaginationMenu, {
      ...uiState.pagination,
      ...eventHandlers
    });
  }
  renderToolbar() {
    const {
      rows,
      options,
      columns,
      uiState,
      eventHandlers,
      children
    } = this.props;
    const props = {
      rows,
      options,
      columns,
      uiState,
      eventHandlers,
      children
    };
    if (!options || !options.toolbar) return null;
    return _jsx(TableToolbar, {
      ...props
    });
  }
  renderActionBar() {
    const {
      rows,
      columns,
      options,
      actions,
      uiState,
      eventHandlers,
      children
    } = this.props;
    let props = {
      rows,
      columns,
      options,
      actions,
      uiState,
      eventHandlers
    };
    if (!actions || !actions.length) return null;
    if (!this.renderToolbar() && children) props = Object.assign({}, props, {
      children
    });
    return _jsx(ActionToolbar, {
      ...props
    });
  }
  renderEmptyState() {
    const {
      uiState,
      options
    } = this.props;
    const {
      emptinessCulprit
    } = uiState ? uiState : {};
    const {
      renderEmptyState
    } = options ? options : {};
    return renderEmptyState ? renderEmptyState() : _jsx(EmptyState, {
      culprit: emptinessCulprit
    });
  }
  render() {
    let {
      rows,
      filteredRows,
      options,
      columns,
      actions,
      uiState,
      eventHandlers,
      headerWrapperStyle
    } = this.props;
    if (!filteredRows) filteredRows = [...rows];
    const props = {
      rows,
      filteredRows,
      options,
      columns,
      actions,
      uiState,
      eventHandlers,
      headerWrapperStyle
    };
    const Toolbar = this.renderToolbar;
    const ActionBar = this.renderActionBar;
    const PageNav = this.renderPaginationMenu;
    const Empty = this.renderEmptyState;
    const className = (options.className ?? '') + ' Mesa MesaComponent';
    return _jsxs("div", {
      className: className,
      style: options.style,
      children: [_jsx(Toolbar, {}), _jsx(ActionBar, {}), _jsx(PageNav, {}), rows.length ? _jsxs(React.Fragment, {
        children: [_jsx(DataTable, {
          ...props
        }), filteredRows.length ? null : _jsx(Empty, {})]
      }) : _jsx(Empty, {}), _jsx(PageNav, {})]
    });
  }
}
MesaController.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  filteredRows: PropTypes.array,
  options: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.shape({
    element: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
    handler: PropTypes.func,
    callback: PropTypes.func
  })),
  uiState: PropTypes.object,
  eventHandlers: PropTypes.objectOf(PropTypes.func)
};
export default MesaController;