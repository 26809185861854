import * as React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SvgTableDownload = props => _jsxs("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16",
  xmlSpace: "preserve",
  width: "1em",
  height: "1em",
  ...props,
  children: [_jsx("path", {
    d: "M13.472 2.364H.494A.494.494 0 0 0 0 2.858v8.5c0 .276.225.494.494.494h10.791c-.225-.225-.449-.449-.674-.667H7.314V8.362h3.618v-.667H7.314V4.866H13.3v.738c.257 0 .488.096.667.263V2.858a.495.495 0 0 0-.495-.494zm-6.819 8.821H.667V8.362h5.986v2.823zm0-3.49H.667V4.866h5.986v2.829z"
  }), _jsx("path", {
    d: "M13.666 7.653v1.305c0 .071 0 .071.073.071h1.315c.183 0 .312.103.341.275a.316.316 0 0 1-.099.286l-.56.56-1.856 1.856c-.098.098-.21.147-.345.099a.423.423 0 0 1-.148-.097c-.605-.601-1.208-1.205-1.811-1.807l-.61-.61c-.147-.148-.141-.351.01-.487.069-.062.153-.076.241-.076h1.301c.077 0 .077 0 .077-.077v-2.25c0-.125.028-.24.124-.329a.358.358 0 0 1 .246-.103c.441-.001.883-.003 1.324.001.218.002.374.18.375.419.003.432.003.533.002.964zM15.528 13.636H9.732a.472.472 0 0 1 0-.944h5.796a.472.472 0 0 1 0 .944z"
  })]
});
export default SvgTableDownload;