import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
class SelectBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const {
      onChange
    } = this.props;
    const value = e.target.value;
    if (onChange) onChange(value);
  }
  getOptions() {
    let {
      options
    } = this.props;
    if (!Array.isArray(options)) return [];
    options = options.map(option => {
      return typeof option === 'object' && 'name' in option && 'value' in option ? option : {
        name: option.toString(),
        value: option
      };
    });
    return options;
  }
  render() {
    const {
      name,
      className,
      selected
    } = this.props;
    let options = this.getOptions();
    return _jsx("select", {
      name: name,
      className: className,
      onChange: this.handleChange,
      value: selected,
      children: options.map(_ref => {
        let {
          value,
          name
        } = _ref;
        return _jsx("option", {
          value: value,
          children: name
        }, value);
      })
    });
  }
}
export default SelectBox;