import React from 'react';
import { OptionsDefaults } from './Defaults';
import OverScroll from './Components/OverScroll';
import TruncatedText from './Components/TruncatedText';
import { stringValue } from './Utils/Utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Templates = {
  textCell(_ref) {
    let {
      key,
      value,
      row,
      rowIndex,
      column
    } = _ref;
    const {
      truncated
    } = column;
    const className = 'Cell Cell-' + key;
    const text = stringValue(value);
    return truncated ? _jsx(TruncatedText, {
      className: className,
      cutoff: truncated ? OptionsDefaults.overflowHeight : null,
      text: text
    }) : _jsx("div", {
      className: className,
      children: text
    });
  },
  numberCell(_ref2) {
    let {
      key,
      value,
      row,
      rowIndex,
      column
    } = _ref2;
    const className = 'Cell NumberCell Cell-' + key;
    const display = typeof value === 'number' ? value.toLocaleString() : stringValue(value);
    return _jsx("div", {
      className: className,
      children: display
    });
  },
  wdkLinkCell(_ref3) {
    let {
      key,
      value,
      row,
      rowIndex,
      column
    } = _ref3;
    const className = 'Cell wdkLinkCell Cell-' + key;
    let {
      displayText,
      url
    } = value;
    let href = url ? url : '#';
    let text = displayText.length ? value.displayText : href;
    text = _jsx("div", {
      dangerouslySetInnerHTML: {
        __html: text
      }
    });
    let target = '_blank';
    const props = {
      href,
      target,
      className
    };
    return _jsx("a", {
      ...props,
      children: text
    });
  },
  linkCell(_ref4) {
    let {
      key,
      value,
      row,
      rowIndex,
      column
    } = _ref4;
    const className = 'Cell LinkCell Cell-' + key;
    const defaults = {
      href: null,
      target: '_blank',
      text: ''
    };
    let {
      href,
      target,
      text
    } = typeof value === 'object' ? value : defaults;
    // href = href ? href : typeof value === 'string' ? value : '#';
    href = href ? href : typeof value === 'string' ? value : null;
    text = text.length ? text : href;
    const props = {
      href,
      target,
      className,
      name: text
    };
    return href && _jsx("a", {
      ...props,
      children: text
    });
  },
  htmlCell(_ref5) {
    let {
      key,
      value,
      row,
      rowIndex,
      column
    } = _ref5;
    const {
      truncated
    } = column;
    const className = 'Cell HtmlCell Cell-' + key;
    const content = _jsx("div", {
      dangerouslySetInnerHTML: {
        __html: value
      }
    });
    const size = truncated === true ? '16em' : truncated;
    return truncated ? _jsx(OverScroll, {
      className: className,
      size: size,
      children: content
    }) : _jsx("div", {
      className: className,
      children: content
    });
  },
  heading(_ref6) {
    let {
      key,
      name
    } = _ref6;
    const className = 'Cell HeadingCell HeadingCell-' + key;
    const content = _jsx("b", {
      children: name || key
    });
    return _jsx("div", {
      className: className,
      children: content
    });
  }
};
export default Templates;