import React from 'react';
import ExpansionCell from './ExpansionCell';
import HeadingCell from './HeadingCell';
import SelectionCell from './SelectionCell';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
class HeadingRow extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      filteredRows,
      options,
      columns,
      actions,
      uiState,
      eventHandlers,
      offsetLeft
    } = this.props;
    const {
      isRowSelected,
      columnDefaults,
      childRow,
      getRowId
    } = options ? options : {};
    const {
      sort,
      expandedRows
    } = uiState ? uiState : {};
    const {
      onRowSelect,
      onRowDeselect,
      onExpandedRowsChange
    } = eventHandlers ? eventHandlers : {};
    const hasSelectionColumn = [isRowSelected, onRowSelect, onRowDeselect].every(fn => typeof fn === 'function');
    const hasExpansionColumn = childRow != null && onExpandedRowsChange != null && expandedRows != null && getRowId != null;
    const rowCount = columns.reduce((count, column) => {
      const thisCount = Array.isArray(column.renderHeading) ? column.renderHeading.length : 1;
      return Math.max(thisCount, count);
    }, 1);
    const headingRows = new Array(rowCount).fill({}).map((blank, index) => {
      const isFirstRow = !index;
      const cols = columns.map(col => {
        const output = Object.assign({}, col);
        if (Array.isArray(col.renderHeading)) {
          output.renderHeading = col.renderHeading.length > index ? col.renderHeading[index] : false;
        } else if (!isFirstRow) {
          output.renderHeading = false;
        }
        return output;
      });
      return {
        cols,
        isFirstRow
      };
    });
    return _jsx("thead", {
      children: headingRows.map((_ref, index) => {
        let {
          cols,
          isFirstRow
        } = _ref;
        return _jsxs("tr", {
          className: "Row HeadingRow",
          children: [hasExpansionColumn && _jsx(ExpansionCell, {
            inert: !isFirstRow,
            heading: true,
            rows: filteredRows,
            childRow: childRow,
            getRowId: getRowId,
            onExpandedRowsChange: onExpandedRowsChange,
            expandedRows: expandedRows
          }, "_expansion"), hasSelectionColumn && _jsx(SelectionCell, {
            inert: !isFirstRow,
            heading: true,
            rows: filteredRows,
            options: options,
            eventHandlers: eventHandlers,
            isRowSelected: isRowSelected
          }, "_selection"), cols.map((column, columnIndex) => {
            if (typeof columnDefaults === 'object') column = Object.assign({}, columnDefaults, column);
            return _jsx(HeadingCell, {
              sort: sort,
              primary: isFirstRow,
              column: column,
              headingRowIndex: index,
              offsetLeft: offsetLeft,
              columnIndex: columnIndex,
              eventHandlers: eventHandlers
            }, `${column.key}-${columnIndex}`);
          })]
        }, index);
      })
    });
  }
}
export default HeadingRow;