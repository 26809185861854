import React from 'react';
import { Tooltip } from '../../info/Tooltip';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const MesaTooltip = _ref => {
  let {
    hideDelay,
    showDelay,
    children,
    className,
    content,
    corner,
    position,
    style,
    getPosition,
    renderHtml
  } = _ref;
  const getPositionResult = getPosition?.();
  const currentPosition = position ? {
    top: position?.top ?? 0,
    left: position?.left ?? 0
  } : getPositionResult ? {
    top: getPositionResult?.top ?? 0,
    left: getPositionResult?.left ?? 0
  } : {
    top: 0,
    left: 0
  };
  const finalStyle = {
    top: currentPosition.top,
    left: currentPosition.left,
    ...style
  };
  return _jsx(Tooltip, {
    title: renderHtml ? _jsx("div", {
      dangerouslySetInnerHTML: {
        __html: content
      }
    }) : content ?? _jsx(_Fragment, {}),
    leaveDelay: hideDelay,
    enterDelay: showDelay,
    className: (className ?? '') + (corner ? ` ${corner}` : ''),
    style: finalStyle,
    tabIndex: 0,
    children: children
  });
};
export default MesaTooltip;