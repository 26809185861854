import * as React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const SvgUndo = props => _jsx("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  xmlSpace: "preserve",
  width: "1em",
  height: "1em",
  ...props,
  children: _jsx("path", {
    d: "M6.195 10.126c.074.05.159.089.222.151.823.819 1.646 1.637 2.462 2.463.366.371.446.885.232 1.336a1.18 1.18 0 0 1-1.134.669 1.153 1.153 0 0 1-.764-.347c-1.574-1.571-3.15-3.139-4.718-4.716-.466-.469-.457-1.185.011-1.655a1590.21 1590.21 0 0 1 4.685-4.68c.495-.493 1.22-.497 1.69-.025.469.471.461 1.193-.031 1.691-.805.813-1.617 1.62-2.428 2.428-.063.063-.143.11-.215.164l.034.079h.274c3.376 0 5.245-.006 8.621.002 3.082.007 5.674 1.994 6.482 4.947 1.073 3.927-1.701 7.961-5.745 8.352a7.323 7.323 0 0 1-.709.039c-2.853.003-4.198.003-7.051.001-.851-.001-1.423-.734-1.191-1.515.149-.5.596-.833 1.142-.837.654-.005 4.885.005 7.1-.004 2.114-.008 3.854-1.46 4.256-3.533.495-2.555-1.48-5.059-4.08-5.087-3.507-.038-5.506-.011-9.013-.011-.023 0-.046.007-.069.01l-.063.078z"
  })
});
export default SvgUndo;