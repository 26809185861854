import React, { useMemo, useCallback } from 'react';

import { Parameter } from '@veupathdb/wdk-client/lib/Utils/WdkModel';
import {
  toMultiValueArray,
  toMultiValueString,
} from '@veupathdb/wdk-client/lib/Views/Question/Params/EnumParamUtils';
import { Props as ParameterProps } from '@veupathdb/wdk-client/lib/Views/Question/Params/Utils';

const detailTypes = ['I', 'II', 'III', 'II or III', 'nd', 'u-1', 'u-2', 'u-3'];
const rawRows = [
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 4],
  [2, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
  [3, 3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0],
  [4, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0],
  [5, 5, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0],
  [6, 0, 0, 0, 2, 0, 1, 5, 0, 0, 0, 0],
  [7, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0],
  [8, 0, 2, 2, 2, 2, 2, 1, 2, 2, 2, 2],
  [9, 5, 1, 1, 2, 2, 1, 1, 2, 1, 1, 0],
  [10, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [11, 0, 0, 1, 2, 2, 2, 0, 2, 0, 1, 2],
  [12, 0, 2, 2, 0, 0, 2, 1, 2, 2, 0, 2],
  [13, 0, 0, 0, 0, 0, 2, 1, 2, 2, 0, 2],
  [14, 0, 2, 2, 2, 2, 2, 2, 0, 2, 2, 2],
  [15, 5, 0, 1, 2, 2, 2, 2, 0, 0, 2, 0],
  [16, 0, 0, 1, 2, 2, 0, 2, 0, 2, 2, 0],
  [17, 5, 0, 1, 2, 2, 2, 5, 0, 0, 2, 0],
  [18, 0, 0, 0, 2, 0, 2, 1, 0, 2, 2, 0],
  [19, 0, 2, 2, 2, 2, 2, 0, 0, 0, 5, 0],
  [20, 5, 1, 1, 2, 2, 1, 1, 2, 1, 6, 0],
  [21, 0, 2, 2, 2, 2, 2, 0, 0, 0, 2, 2],
  [22, 5, 0, 1, 2, 2, 2, 5, 0, 2, 2, 2],
  [23, 0, 0, 1, 2, 0, 2, 1, 0, 2, 5, 0],
  [24, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 2],
  [25, 0, 2, 2, 0, 2, 2, 2, 2, 2, 0, 0],
  [26, 1, 2, 2, 2, 2, 2, 0, 2, 2, 0, 2],
  [27, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
  [28, 0, 0, 0, 0, 0, 0, 1, 0, 2, 0, 2],
  [29, 0, 0, 1, 2, 0, 2, 1, 0, 2, 2, 0],
  [30, 0, 2, 2, 0, 2, 2, 2, 2, 2, 0, 2],
  [31, 0, 2, 2, 2, 0, 2, 2, 2, 2, 0, 2],
  [32, 0, 2, 2, 2, 2, 1, 0, 0, 0, 0, 0],
  [33, 5, 0, 1, 2, 0, 2, 5, 0, 0, 0, 0],
  [34, 5, 0, 1, 2, 2, 2, 1, 0, 0, 5, 0],
  [35, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 2],
  [36, 0, 0, 0, 2, 0, 2, 1, 0, 2, 0, 2],
  [37, 0, 1, 1, 2, 2, 2, 5, 0, 0, 2, 0],
  [38, 0, 2, 2, 2, 0, 0, 0, 2, 0, 0, 2],
  [39, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1],
  [40, 5, 0, 1, 2, 2, 2, 2, 2, 0, 2, 0],
  [41, 0, 0, 0, 2, 0, 1, 0, 0, 0, 0, 0],
  [42, 0, 0, 0, 2, 2, 1, 0, 0, 0, 5, 0],
  [43, 0, 0, 1, 0, 1, 0, 0, 0, 0, 5, 0],
  [44, 0, 0, 1, 2, 0, 2, 1, 0, 0, 7, 0],
  [45, 0, 2, 2, 2, 0, 1, 1, 2, 0, 0, 2],
  [46, 0, 2, 2, 2, 0, 2, 1, 0, 2, 2, 0],
  [47, 0, 2, 2, 2, 2, 1, 5, 0, 0, 1, 0],
  [48, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
  [49, 1, 1, 1, 0, 1, 1, 1, 1, 2, 1, 4],
  [50, 1, 2, 2, 0, 2, 0, 2, 2, 2, 2, 2],
  [51, 5, 0, 1, 2, 0, 2, 1, 0, 0, 0, 0],
  [52, 5, 0, 1, 2, 0, 2, 6, 0, 0, 2, 0],
  [53, 5, 0, 1, 2, 2, 2, 1, 0, 0, 2, 0],
  [54, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 1],
  [55, 0, 0, 0, 0, 2, 0, 5, 0, 0, 0, 0],
  [56, 0, 0, 0, 2, 0, 1, 5, 0, 2, 0, 0],
  [57, 0, 0, 0, 2, 0, 1, 5, 0, 2, 1, 2],
  [58, 0, 0, 0, 2, 0, 2, 5, 2, 2, 0, 0],
  [59, 0, 0, 0, 2, 2, 1, 5, 0, 0, 0, 0],
  [60, 0, 0, 1, 0, 2, 2, 2, 0, 2, 2, 0],
  [61, 0, 0, 1, 2, 0, 2, 1, 2, 0, 6, 0],
  [62, 0, 0, 1, 2, 0, 2, 1, 2, 2, 2, 0],
  [63, 0, 0, 1, 2, 2, 2, 0, 2, 0, 1, 0],
  [64, 0, 0, 1, 2, 2, 2, 5, 0, 0, 6, 0],
  [65, 0, 0, 1, 2, 2, 2, 5, 0, 0, 2, 0],
  [66, 0, 1, 1, 2, 1, 1, 1, 5, 0, 6, 0],
  [67, 0, 2, 2, 2, 0, 2, 0, 2, 2, 5, 2],
  [68, 0, 2, 2, 2, 0, 2, 2, 2, 2, 2, 2],
  [69, 0, 2, 2, 2, 2, 1, 0, 2, 0, 1, 0],
  [70, 0, 2, 2, 2, 2, 1, 5, 0, 0, 0, 2],
  [71, 0, 2, 2, 2, 2, 2, 1, 0, 2, 2, 0],
  [72, 0, 2, 2, 2, 2, 2, 2, 2, 2, 6, 2],
  [73, 1, 2, 2, 0, 0, 0, 2, 2, 2, 0, 0],
  [74, 1, 2, 2, 2, 1, 1, 1, 2, 1, 1, 0],
  [75, 5, 0, 1, 2, 2, 2, 1, 0, 0, 2, 2],
  [76, 5, 2, 2, 2, 2, 2, 5, 0, 0, 2, 0],
  [77, 0, 0, 0, 0, 0, 0, 5, 0, 0, 2, 2],
  [78, 0, 0, 0, 0, 0, 2, 1, 0, 2, 0, 2],
  [79, 0, 0, 0, 0, 2, 0, 0, 2, 2, 0, 2],
  [80, 0, 0, 0, 2, 0, 1, 0, 0, 0, 5, 0],
  [81, 0, 0, 0, 2, 0, 1, 5, 0, 0, 2, 2],
  [82, 0, 0, 0, 2, 0, 2, 1, 0, 0, 0, 2],
  [83, 0, 0, 0, 2, 0, 2, 1, 2, 2, 0, 2],
  [84, 0, 0, 0, 2, 0, 2, 5, 0, 0, 2, 0],
  [85, 0, 0, 0, 2, 2, 1, 5, 0, 0, 1, 0],
  [86, 0, 0, 0, 2, 2, 1, 5, 0, 0, 2, 0],
  [87, 0, 0, 0, 2, 2, 2, 0, 0, 2, 0, 2],
  [88, 0, 0, 0, 2, 2, 2, 1, 0, 2, 0, 2],
  [89, 0, 0, 0, 2, 2, 2, 1, 2, 2, 2, 2],
  [90, 0, 0, 0, 2, 2, 2, 2, 2, 2, 0, 2],
  [91, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0],
  [92, 0, 0, 1, 0, 2, 1, 1, 0, 0, 1, 0],
  [93, 0, 0, 1, 0, 2, 1, 5, 0, 0, 2, 0],
  [94, 0, 0, 1, 0, 2, 2, 0, 0, 0, 1, 0],
  [95, 0, 0, 1, 0, 2, 2, 1, 0, 0, 2, 0],
  [96, 0, 0, 1, 0, 2, 2, 1, 2, 0, 2, 2],
  [97, 0, 0, 1, 0, 2, 2, 1, 2, 0, 2, 0],
  [98, 0, 0, 1, 0, 2, 2, 2, 2, 0, 2, 2],
  [99, 0, 0, 1, 0, 2, 2, 5, 0, 0, 2, 0],
  [100, 0, 0, 1, 0, 2, 2, 5, 0, 2, 2, 0],
  [101, 0, 0, 1, 2, 0, 2, 1, 0, 0, 6, 0],
  [102, 0, 0, 1, 2, 0, 2, 1, 0, 2, 5, 2],
  [104, 0, 0, 1, 2, 0, 2, 5, 0, 0, 2, 0],
  [105, 0, 0, 1, 2, 2, 1, 5, 2, 2, 2, 0],
  [106, 0, 0, 1, 2, 2, 1, 5, 0, 0, 1, 0],
  [107, 0, 0, 1, 2, 2, 1, 5, 0, 0, 2, 0],
  [108, 0, 0, 1, 2, 2, 2, 1, 0, 0, 2, 0],
  [109, 0, 0, 1, 2, 2, 2, 2, 0, 2, 2, 2],
  [110, 0, 0, 1, 2, 2, 2, 5, 2, 0, 2, 0],
  [111, 0, 0, 5, 2, 2, 2, 5, 0, 2, 2, 0],
  [112, 0, 1, 1, 0, 0, 0, 2, 1, 2, 0, 4],
  [113, 0, 1, 1, 2, 1, 1, 1, 4, 2, 1, 4],
  [114, 0, 2, 2, 0, 2, 2, 2, 0, 2, 0, 0],
  [115, 0, 2, 2, 0, 2, 2, 2, 2, 2, 2, 0],
  [116, 0, 2, 2, 2, 0, 2, 1, 2, 2, 2, 2],
  [117, 0, 2, 2, 2, 0, 2, 5, 0, 0, 5, 2],
  [118, 0, 2, 2, 2, 2, 0, 0, 2, 2, 0, 4],
  [119, 0, 2, 2, 2, 2, 1, 5, 0, 0, 5, 0],
  [120, 0, 2, 2, 2, 2, 2, 0, 0, 2, 2, 2],
  [121, 0, 2, 2, 2, 2, 2, 0, 2, 0, 2, 2],
  [122, 0, 2, 2, 2, 2, 2, 0, 2, 2, 0, 2],
  [123, 0, 2, 2, 2, 2, 2, 0, 2, 2, 2, 2],
  [124, 0, 2, 2, 2, 2, 2, 1, 2, 0, 5, 0],
  [125, 0, 2, 2, 2, 2, 2, 1, 2, 2, 6, 2],
  [126, 0, 4, 0, 2, 2, 1, 5, 0, 0, 5, 0],
  [127, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2],
  [128, 1, 1, 1, 1, 1, 1, 1, 2, 1, 1, 0],
  [129, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1],
  [130, 1, 2, 2, 0, 2, 2, 1, 0, 2, 2, 2],
  [131, 1, 2, 2, 1, 1, 0, 2, 2, 1, 1, 0],
  [132, 1, 2, 2, 2, 1, 1, 1, 2, 2, 1, 2],
  [133, 1, 2, 2, 2, 2, 0, 2, 2, 2, 2, 2],
  [134, 5, 0, 1, 2, 0, 2, 1, 2, 2, 0, 2],
  [135, 5, 0, 1, 2, 2, 2, 1, 0, 2, 2, 0],
  [136, 5, 0, 5, 2, 2, 2, 1, 0, 0, 2, 0],
  [137, 5, 1, 1, 2, 2, 1, 1, 2, 1, 2, 0],
  [138, 5, 2, 2, 2, 2, 2, 2, 0, 2, 2, 2],
  [139, 1, 1, 1, 2, 2, 2, 1, 1, 2, 2, 0],
  [140, 1, 2, 2, 2, 2, 2, 1, 2, 2, 0, 2],
  [141, 1, 2, 2, 2, 2, 2, 2, 2, 2, 0, 2],
  [142, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0],
  [143, 0, 0, 0, 2, 0, 2, 2, 0, 2, 0, 2],
  [144, 0, 0, 0, 2, 0, 2, 5, 2, 0, 0, 0],
  [145, 0, 0, 0, 2, 0, 1, 0, 0, 0, 0, 2],
  [146, 0, 0, 0, 2, 1, 1, 0, 2, 2, 1, 2],
  [147, 0, 0, 1, 0, 2, 2, 0, 0, 0, 2, 0],
  [148, 0, 0, 1, 2, 0, 2, 1, 0, 0, 2, 0],
  [149, 0, 0, 1, 2, 2, 1, 1, 0, 0, 0, 0],
  [150, 0, 0, 1, 2, 2, 1, 5, 2, 0, 0, 0],
  [152, 0, 0, 5, 2, 2, 2, 1, 0, 0, 2, 0],
  [153, 0, 1, 0, 2, 0, 2, 0, 2, 2, 1, 2],
  [154, 0, 1, 1, 2, 1, 1, 1, 5, 2, 1, 0],
  [155, 0, 2, 2, 0, 0, 2, 0, 2, 2, 0, 4],
  [156, 0, 2, 2, 2, 0, 0, 2, 2, 2, 0, 2],
  [157, 0, 2, 2, 2, 0, 2, 0, 2, 2, 2, 2],
  [158, 0, 2, 2, 2, 0, 2, 5, 2, 2, 2, 0],
  [159, 0, 2, 2, 2, 2, 0, 0, 2, 0, 2, 2],
  [160, 0, 2, 2, 2, 2, 2, 0, 2, 2, 5, 2],
  [161, 0, 2, 2, 2, 2, 2, 0, 0, 2, 2, 0],
  [162, 0, 2, 2, 2, 2, 2, 1, 0, 0, 2, 0],
  [163, 0, 2, 2, 2, 2, 2, 1, 0, 2, 2, 2],
  [164, 0, 2, 2, 2, 2, 2, 2, 0, 0, 2, 0],
  [165, 0, 2, 2, 2, 2, 2, 5, 0, 0, 2, 2],
  [166, 0, 2, 2, 2, 2, 2, 5, 0, 2, 2, 2],
  [167, 1, 0, 0, 0, 0, 0, 0, 2, 2, 2, 2],
  [168, 1, 1, 1, 1, 2, 1, 1, 1, 2, 1, 0],
  [169, 1, 1, 1, 2, 2, 2, 2, 2, 1, 1, 2],
  [170, 1, 2, 2, 2, 0, 2, 2, 2, 2, 2, 2],
  [171, 5, 0, 0, 2, 2, 0, 5, 2, 2, 0, 2],
  [172, 5, 0, 1, 0, 2, 1, 5, 2, 2, 2, 0],
  [173, 5, 0, 1, 2, 2, 1, 5, 0, 0, 2, 0],
  [174, 5, 0, 1, 2, 2, 1, 5, 2, 0, 2, 0],
  [175, 5, 0, 1, 2, 2, 2, 2, 0, 0, 5, 0],
  [176, 5, 1, 1, 1, 1, 1, 1, 2, 1, 2, 0],
  [177, 0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 2],
  [178, 0, 0, 0, 2, 0, 2, 1, 0, 0, 2, 0],
  [179, 0, 0, 1, 2, 0, 2, 0, 2, 2, 2, 2],
  [180, 0, 2, 2, 0, 0, 0, 1, 2, 2, 0, 0],
  [185, 1, 1, 1, 2, 2, 1, 2, 2, 2, 6, 2],
  [186, 1, 2, 2, 2, 2, 0, 0, 0, 2, 0, 2],
  [187, 1, 2, 2, 2, 2, 2, 1, 2, 2, 2, 2],
  [188, 1, 2, 2, 2, 2, 2, 2, 2, 0, 0, 2],
  [189, 5, 0, 1, 0, 0, 0, 0, 0, 0, 2, 0],
  [190, 0, 2, 2, 2, 0, 0, 1, 2, 2, 0, 2],
  [191, 0, 0, 0, 0, 0, 0, 1, 2, 2, 0, 2],
  [197, 0, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0],
  [202, 5, 0, 1, 2, 2, 1, 2, 0, 0, 2, 0],
  [203, 0, 0, 0, 2, 0, 1, 1, 2, 2, 0, 2],
  [231, 0, 2, 2, 2, 1, 2, 1, 1, 1, 2, 1],
];

const rowKeys = rawRows.map(([rowKey]) => rowKey);
const rowDetails = rawRows.map(([, ...rawDetailEntries]) =>
  rawDetailEntries.map((rawDetailEntry) => detailTypes[rawDetailEntry])
);

const annotationEntries = [
  null,
  null,
  null,
  "5'+3'",
  'alternative',
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
];
const headerEntries = [
  null,
  'Genotype#',
  'SAG1',
  'SAG2',
  'SAG2',
  'SAG3',
  'BTUB',
  'GRA6',
  'c22-8',
  'c29-2',
  'L358',
  'PK1',
  'Apico',
];

const AnnotationsRow = () => (
  <tbody>
    <tr>
      {annotationEntries.map((entry, j) => (
        <td key={j}>{entry}</td>
      ))}
    </tr>
  </tbody>
);

const HeaderRow = () => (
  <tbody>
    <tr>
      {headerEntries.map((entry, j) => (
        <th key={j}>{entry}</th>
      ))}
    </tr>
  </tbody>
);

interface TableBodyProps {
  onToggle: (
    itemKey: number
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkedValues: Record<string, boolean>;
}

const TableBody: React.FunctionComponent<TableBodyProps> = ({
  checkedValues,
  onToggle,
}) => (
  <tbody>
    {rowKeys.map((rowKey, i) => (
      <tr key={rowKey}>
        <td key={0}>
          <input
            type="checkbox"
            value={rowKey}
            checked={checkedValues[rowKey]}
            onChange={onToggle(rowKey)}
          />
        </td>
        <td key={1}>{rowKey}</td>
        {rowDetails[i].map((detailEntry, j) => (
          <td key={j + 2}>{detailEntry}</td>
        ))}
      </tr>
    ))}
  </tbody>
);

type ByGenotypeNumberCheckboxProps = ParameterProps<Parameter>;

const EMPTY_VALUE = toMultiValueString([]);

export const ByGenotypeNumberCheckbox: React.FunctionComponent<ByGenotypeNumberCheckboxProps> =
  ({ onParamValueChange, value = EMPTY_VALUE }) => {
    const orderedCheckedValues = useMemo(
      () => toMultiValueArray(value),
      [value]
    );
    const checkedValues = useMemo(
      () =>
        orderedCheckedValues.reduce(
          (memo, checkedItem) => ({ ...memo, [checkedItem]: true }),
          {}
        ),
      [orderedCheckedValues]
    );

    const onToggle = useCallback(
      (itemKey: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const predecessors = orderedCheckedValues.filter(
          (x) => parseInt(x) < itemKey
        );
        const successors = orderedCheckedValues.filter(
          (x) => parseInt(x) > itemKey
        );

        const newParamValueItems = event.target.checked
          ? [...predecessors, `${itemKey}`, ...successors]
          : [...predecessors, ...successors];

        onParamValueChange(toMultiValueString(newParamValueItems));
      },
      [onParamValueChange, orderedCheckedValues]
    );

    return (
      <table>
        <AnnotationsRow />
        <HeaderRow />
        <TableBody onToggle={onToggle} checkedValues={checkedValues} />
      </table>
    );
  };
