import React from 'react';
import DataRow from './DataRow';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
class DataRowList extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      props
    } = this;
    const {
      rows,
      filteredRows
    } = props;
    return _jsx("tbody", {
      className: "DataRowList",
      children: filteredRows.map((row, rowIndex) => _jsx(DataRow, {
        row: row,
        rowIndex: rowIndex,
        ...props
      }, rowIndex))
    });
  }
}
export default DataRowList;