import React, { useCallback } from 'react';
import { ArrowDown, ArrowRight } from '../../icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const EMPTY_ARRAY = [];
export default function ExpansionCell(_ref) {
  let {
    rows,
    row,
    onExpandedRowsChange,
    expandedRows = EMPTY_ARRAY,
    getRowId,
    inert,
    heading
  } = _ref;
  const expandAllRows = useCallback(() => onExpandedRowsChange(rows.map(row => getRowId(row))), [onExpandedRowsChange, rows, getRowId]);
  const collapseAllRows = useCallback(() => onExpandedRowsChange([]), [onExpandedRowsChange]);
  const renderPageExpansionToggle = useCallback(() => {
    const areAllRowsExpanded = rows.length === expandedRows.length;
    const handler = e => {
      e.stopPropagation();
      e.preventDefault();
      return areAllRowsExpanded ? collapseAllRows() : expandAllRows();
    };
    const title = 'Show or hide all row details';
    return _jsx("th", {
      className: "HeadingCell ChildRowToggle",
      children: inert ? null : areAllRowsExpanded ? _jsx("button", {
        title: title,
        onClick: handler,
        children: _jsx(ArrowDown, {})
      }) : _jsx("button", {
        title: title,
        onClick: handler,
        children: _jsx(ArrowRight, {})
      })
    });
  }, [rows, expandedRows, collapseAllRows, expandAllRows, inert]);
  const renderRowExpansionToggle = useCallback(() => {
    const isExpanded = expandedRows.includes(getRowId(row));
    const handler = e => {
      e.stopPropagation();
      e.preventDefault();
      if (isExpanded) {
        // remove from expandedRows
        onExpandedRowsChange(expandedRows.filter(rowId => rowId != getRowId(row)));
      } else {
        // expand and add to expandedRows
        onExpandedRowsChange(expandedRows.concat(getRowId(row)));
      }
    };
    return _jsx("td", {
      className: "ChildRowToggle",
      children: inert ? null : isExpanded ? _jsx("button", {
        onClick: handler,
        children: _jsx(ArrowDown, {})
      }) : _jsx("button", {
        onClick: handler,
        children: _jsx(ArrowRight, {})
      })
    });
  }, [expandedRows, row, getRowId, onExpandedRowsChange, inert]);
  return heading ? renderPageExpansionToggle() : renderRowExpansionToggle();
}