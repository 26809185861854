import * as React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SvgChevronRight = props => _jsxs("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  height: "1em",
  viewBox: "0 0 24 24",
  width: "1em",
  ...props,
  children: [_jsx("path", {
    d: "M0 0h24v24H0z",
    fill: "none"
  }), _jsx("path", {
    d: "M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
  })]
});
export default SvgChevronRight;