import React from 'react';
import SelectBox from '../Components/SelectBox';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
class RowsPerPageMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(itemsPerPage) {
    const {
      onRowsPerPageChange
    } = this.props;
    itemsPerPage = parseInt(itemsPerPage);
    if (onRowsPerPageChange) onRowsPerPageChange(itemsPerPage);
  }
  render() {
    let {
      rowsPerPage,
      rowsPerPageOptions
    } = this.props;
    if (!rowsPerPageOptions) rowsPerPageOptions = [5, 10, 20, 50, 100, 500, 1000];
    return _jsxs("div", {
      className: "PaginationEditor",
      children: [_jsx("span", {
        children: "Rows per page: "
      }), _jsx(SelectBox, {
        selected: rowsPerPage,
        options: rowsPerPageOptions,
        onChange: this.handleChange
      })]
    });
  }
}
export default RowsPerPageMenu;