import React from "react";
import { useRef, useEffect } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * React Component that provides a 3-state checkbox
 */
export default function IndeterminateCheckbox(_ref) {
  let {
    checked,
    indeterminate,
    name,
    onChange,
    value
  } = _ref;
  const nameProp = name ? {
    name
  } : {};
  const nodeRef = useRef(null);
  useEffect(() => {
    if (!nodeRef.current) return;
    nodeRef.current.indeterminate = indeterminate;
  }, [indeterminate]);
  return _jsx("input", {
    ref: nodeRef,
    type: "checkbox",
    ...nameProp,
    value: value,
    checked: checked,
    onChange: e => onChange(e.target.checked)
  });
}