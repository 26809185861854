import React from 'react';
import Icon from '../Components/Icon';
import { makeClassifier } from '../Utils/Utils';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
class EmptyState extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getCulprit = this.getCulprit.bind(this);
  }
  getCulprit() {
    const {
      culprit
    } = this.props;
    switch (culprit) {
      case 'search':
        return {
          icon: 'search',
          title: 'No Results',
          content: _jsx("div", {
            children: _jsx("p", {
              children: "Sorry, your search returned no results."
            })
          })
        };
      case 'nocolumns':
        return {
          icon: 'columns',
          title: 'No Columns Shown',
          content: _jsx("div", {
            children: _jsx("p", {
              children: "Whoops, looks like you've hidden all columns. Use the column editor to show some columns."
            })
          })
        };
      case 'filters':
        return {
          icon: 'filter',
          title: 'No Filter Results',
          content: _jsx("div", {
            children: _jsx("p", {
              children: "No rows exist that match all of your column filter settings."
            })
          })
        };
      case 'nodata':
      default:
        return {
          icon: 'table',
          title: 'No Results',
          content: null
        };
    }
  }
  render() {
    const culprit = this.getCulprit();
    const emptyStateClass = makeClassifier('EmptyState');
    return _jsx("div", {
      className: emptyStateClass(),
      children: _jsx("div", {
        className: emptyStateClass('BodyWrapper'),
        children: _jsxs("div", {
          className: emptyStateClass('Body'),
          style: {
            textAlign: 'center'
          },
          children: [_jsx(Icon, {
            fa: culprit.icon,
            className: emptyStateClass('Icon')
          }), _jsx("h2", {
            children: culprit.title
          }), culprit.content]
        })
      })
    });
  }
}
export default EmptyState;