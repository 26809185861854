/*    Error Handlers   */
export const fail = function (fn, message) {
  let Err = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Error;
  throw new Err(`<${fn}>: ${message}`);
};
export const warn = (fn, message) => {
  console.warn(`<${fn}>: ${message}`);
  return undefined;
};
export const badType = function (fn, parameter, expected, actual) {
  let fatal = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  const message = `parameter "${parameter}"  is not of type ${expected} (got ${actual})` + (fatal ? '' : `; using empty ${expected}`);
  return fatal ? fail(fn, message, TypeError) : warn(fn, message);
};
export const missingFromState = function (fn, missing) {
  let obj = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let fatal = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  const present = Object.keys(obj).join(', ');
  const message = `state branch "${missing}" not found in state. Found sibling keys: [${present}]`;
  return fatal ? fail(fn, message, ReferenceError) : warn(fn, message);
};