import React from "react";
import { Tooltip as MUITooltip, withStyles } from '@material-ui/core';
import _ from 'lodash';

/**
 * Tooltip will not render if the title is an empty value or a boolean.
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const UnstyledTooltip = props => {
  const {
    title,
    ...otherProps
  } = props;
  const finalTitle = !(_.isEqual(title, {}) || _.isEqual(title, []) || typeof title === 'boolean') ? title : '';
  return _jsx(MUITooltip, {
    title: finalTitle,
    ...otherProps
  });
};
export const Tooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fffde7',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: theme.shadows[1]
  }
}))(UnstyledTooltip);
export { Tooltip as HtmlTooltip };