import React from "react";
import { useMemo } from 'react';
import { makeStyles, styled } from '@material-ui/core';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { mutedBlue, success, error, warning } from '../../definitions/colors';
import { useUITheme } from '../theming';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
/**
 * @param styles A JSS (https://cssinjs.org/?v=v10.9.1-alpha.2) styling
 * object
 * @param displayName The displayName for the returned component
 * @returns A wrapping of a notistack (https://www.iamhosseindhv.com/notistack)
 * SnackbarProvider to which our "defaultStyles" and the consumer's passed
 * "styles" have been applied.
 */
export default function makeSnackbarProvider(styles) {
  let displayName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'SnackbarProvider';
  const useStyles = makeStyles({
    ...styles
  });
  function WrappedSnackbarProvider(_ref) {
    let {
      styleProps,
      ...snackbarProps
    } = _ref;
    const theme = useUITheme();
    const fullStyleProps = useMemo(() => ({
      ...styleProps,
      theme
    }), [theme, styleProps]);
    const StyledSnackbarContent = styled(MaterialDesignContent)({
      '&.notistack-MuiContent-success': makeSnackbarVariantStyles(success),
      '&.notistack-MuiContent-error': makeSnackbarVariantStyles(error),
      '&.notistack-MuiContent-warning': makeSnackbarVariantStyles(warning),
      '&.notistack-MuiContent-info': makeSnackbarVariantStyles(theme?.palette.primary.hue ?? mutedBlue)
    });
    const classes = useStyles(fullStyleProps);
    return _jsx(SnackbarProvider, {
      classes: classes,
      Components: {
        success: StyledSnackbarContent,
        error: StyledSnackbarContent,
        warning: StyledSnackbarContent,
        info: StyledSnackbarContent
      },
      ...snackbarProps,
      children: snackbarProps.children
    });
  }
  WrappedSnackbarProvider.displayName = displayName;
  return WrappedSnackbarProvider;
}
export function makeSnackbarVariantStyles(variantHue) {
  return {
    backgroundColor: variantHue[100],
    border: `1px solid ${variantHue[600]}`,
    color: variantHue[900],
    '& a': {
      color: 'inherit',
      textDecoration: 'underline',
      fontWeight: 'bold'
    },
    '& svg': {
      fill: `${variantHue[600]} !important`
    }
  };
}