import * as React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SvgFilter = props => _jsxs("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  height: "1em",
  viewBox: "0 0 24 24",
  width: "1em",
  ...props,
  children: [_jsx("path", {
    d: "M0 0h24m0 24H0",
    fill: "none"
  }), _jsx("path", {
    d: "M4.25 5.61C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39A.998.998 0 0 0 18.95 4H5.04c-.83 0-1.3.95-.79 1.61z"
  }), _jsx("path", {
    d: "M0 0h24v24H0V0z",
    fill: "none"
  })]
});
export default SvgFilter;