import * as React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SvgWarning = props => _jsxs("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  height: "1em",
  viewBox: "0 0 24 24",
  width: "1em",
  ...props,
  children: [_jsx("path", {
    d: "M0 0h24v24H0z",
    fill: "none"
  }), _jsx("path", {
    d: "M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
  })]
});
export default SvgWarning;