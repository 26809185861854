import React from "react";
import { useEffect, useState } from 'react';
import PopoverButton from '../../buttons/PopoverButton/PopoverButton';
import CheckboxTree, { LinksPosition } from '../checkboxes/CheckboxTree/CheckboxTree';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
function SelectTree(props) {
  const [buttonDisplayContent, setButtonDisplayContent] = useState(props.currentList && props.currentList.length ? props.currentList.join(', ') : props.buttonDisplayContent);
  const {
    selectedList,
    onSelectionChange,
    shouldCloseOnSelection,
    hasPopoverButton = true,
    instantUpdate = true,
    wrapPopover
  } = props;

  // This local state is updated whenever a checkbox is clicked in the species tree.
  // When `instantUpdate` is false, pass the final value to `onSelectionChange` when the popover closes.
  // When it is true we call `onSelectionChange` whenever `localSelectedList` changes
  const [localSelectedList, setLocalSelectedList] = useState(selectedList);

  /** Used as a hack to "auto close" the popover when shouldCloseOnSelection is true */
  const [key, setKey] = useState('');
  useEffect(() => {
    if (!shouldCloseOnSelection) return;
    setKey(selectedList.join(', '));
    onClose();
  }, [shouldCloseOnSelection, localSelectedList]);

  // live updates to caller when needed
  useEffect(() => {
    if (!instantUpdate) return;
    onSelectionChange(localSelectedList);
  }, [onSelectionChange, localSelectedList]);
  function truncatedButtonContent(selectedList) {
    return _jsx("span", {
      style: {
        // this styling is copied from SelectList!
        maxWidth: '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      },
      children: selectedList.join(', ')
    });
  }
  const onClose = () => {
    setButtonDisplayContent(localSelectedList.length ? truncatedButtonContent(localSelectedList) : props.buttonDisplayContent);
    if (!instantUpdate) onSelectionChange(localSelectedList);
  };
  const checkboxTree = _jsx(CheckboxTree, {
    tree: props.tree,
    getNodeId: props.getNodeId,
    getNodeChildren: props.getNodeChildren,
    onExpansionChange: props.onExpansionChange,
    shouldExpandDescendantsWithOneChild: props.shouldExpandDescendantsWithOneChild,
    shouldExpandOnClick: props.shouldExpandOnClick,
    showRoot: props.showRoot,
    renderNode: props.renderNode,
    expandedList: props.expandedList,
    isSelectable: props.isSelectable,
    selectedList: localSelectedList,
    filteredList: props.filteredList,
    customCheckboxes: props.customCheckboxes,
    isMultiPick: props.isMultiPick,
    name: props.name,
    onSelectionChange: setLocalSelectedList,
    currentList: props.currentList,
    defaultList: props.defaultList,
    isSearchable: props.isSearchable,
    autoFocusSearchBox: props.autoFocusSearchBox,
    showSearchBox: props.showSearchBox,
    searchBoxPlaceholder: props.searchBoxPlaceholder,
    searchIconName: props.searchIconName,
    searchBoxHelp: props.searchBoxHelp,
    searchTerm: props.searchTerm,
    onSearchTermChange: props.onSearchTermChange,
    searchPredicate: props.searchPredicate,
    renderNoResults: props.renderNoResults,
    linksPosition: props.linksPosition,
    additionalActions: props.additionalActions,
    additionalFilters: props.additionalFilters,
    isAdditionalFilterApplied: props.isAdditionalFilterApplied,
    wrapTreeSection: props.wrapTreeSection,
    styleOverrides: props.styleOverrides,
    customTreeNodeCssSelectors: props.customTreeNodeCssSelectors
  });
  return hasPopoverButton ? _jsx(PopoverButton, {
    buttonDisplayContent: buttonDisplayContent,
    onClose: onClose,
    isDisabled: props.isDisabled,
    children: _jsx("div", {
      style: {
        padding: '1em 1em 1em .5em',
        width: '30em',
        height: 'min(60vh, 40em)'
      },
      children: wrapPopover ? wrapPopover(checkboxTree) : checkboxTree
    })
  }, shouldCloseOnSelection ? key : '') : _jsx(_Fragment, {
    children: wrapPopover ? wrapPopover(checkboxTree) : checkboxTree
  });
}
const defaultProps = {
  showRoot: false,
  expandedList: null,
  isSelectable: false,
  selectedList: [],
  customCheckboxes: {},
  isMultiPick: true,
  onSelectionChange: () => {},
  isSearchable: false,
  showSearchBox: true,
  searchBoxPlaceholder: 'Search...',
  searchBoxHelp: '',
  searchTerm: '',
  onSearchTermChange: () => {},
  searchPredicate: () => true,
  linksPosition: LinksPosition.Both,
  isDisabled: false,
  instantUpdate: true // Set default value to true
};

SelectTree.defaultProps = defaultProps;
SelectTree.LinkPlacement = LinksPosition;
export default SelectTree;