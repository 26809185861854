import React from 'react';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
class RowCounter extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      rows,
      uiState,
      eventHandlers
    } = this.props;
    const {
      pagination = {},
      filteredRowCount = 0
    } = uiState;
    const {
      totalRows,
      rowsPerPage
    } = pagination;
    const isPaginated = ('onPageChange' in eventHandlers);
    const isSearching = uiState.searchQuery && uiState.searchQuery.length;
    const count = totalRows ? totalRows : rows.length;
    const noun = (isSearching ? 'result' : 'row') + (count - filteredRowCount === 1 ? '' : 's');
    const start = !isPaginated ? null : (pagination.currentPage - 1) * rowsPerPage + 1;
    const end = !isPaginated ? null : start + rowsPerPage > count - filteredRowCount ? count - filteredRowCount : start - 1 + rowsPerPage;
    let filterString = !filteredRowCount ? null : _jsxs("span", {
      className: "faded",
      children: [' ', "(filtered from a total of ", count.toLocaleString(), ")"]
    });
    const remainingRowCount = !filteredRowCount ? count : count - filteredRowCount;
    let countString = _jsxs("span", {
      children: [_jsx("b", {
        children: remainingRowCount.toLocaleString()
      }), " ", noun]
    });
    let allResultsShown = !start || !end || start === 1 && end === remainingRowCount;
    if (!allResultsShown) {
      countString = _jsxs("span", {
        children: [noun, " ", _jsx("b", {
          children: start
        }), " - ", _jsx("b", {
          children: end
        }), " of ", _jsx("b", {
          children: remainingRowCount
        })]
      });
    }
    return _jsxs("div", {
      className: "RowCounter",
      children: [countString, filterString]
    });
  }
}
export default RowCounter;