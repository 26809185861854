import { range } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Components/Icon';
import RowsPerPageMenu from './RowsPerPageMenu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const settings = {
  overflowPoint: 8,
  innerRadius: 2
};
class PaginationMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderEllipsis = this.renderEllipsis.bind(this);
    this.renderPageLink = this.renderPageLink.bind(this);
    this.renderPageList = this.renderPageList.bind(this);
    this.renderPerPageMenu = this.renderPerPageMenu.bind(this);
    this.renderRelativeLink = this.renderRelativeLink.bind(this);
  }
  renderEllipsis() {
    let key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return _jsx("div", {
      className: "ellipsis",
      children: "..."
    }, 'ellipsis-' + key);
  }
  renderPageLink(page, current) {
    let handler = () => this.goToPage(page);
    return _jsx("button", {
      type: "button",
      onClick: handler,
      className: current === page ? 'active' : 'inactive',
      children: page.toLocaleString()
    }, page);
  }
  getTotalPages() {
    const {
      rowsPerPage,
      totalPages,
      totalRows
    } = this.props;
    return totalPages || Math.ceil(totalRows / rowsPerPage);
  }
  getRelativePageNumber(relative) {
    const {
      currentPage
    } = this.props;
    const totalPages = this.getTotalPages();
    switch (relative.toLowerCase()) {
      case 'first':
      case 'start':
        return 1;
      case 'last':
      case 'end':
        return totalPages;
      case 'next':
        return currentPage < totalPages ? currentPage + 1 : 1;
      case 'prev':
      case 'previous':
        return currentPage > 1 ? currentPage - 1 : totalPages;
      default:
        return null;
    }
  }
  getRelativeIcon(relative) {
    switch (relative.toLowerCase()) {
      case 'first':
      case 'start':
        return 'angle-double-left';
      case 'last':
      case 'end':
        return 'angle-double-right';
      case 'next':
        return 'caret-right';
      case 'prev':
      case 'previous':
        return 'caret-left';
      default:
        return null;
    }
  }
  goToPage(page) {
    let {
      onPageChange
    } = this.props;
    if (onPageChange) onPageChange(page);
  }
  renderRelativeLink(_ref) {
    let {
      relative
    } = _ref;
    const page = this.getRelativePageNumber(relative);
    const icon = this.getRelativeIcon(relative);
    return !page || !icon ? null : _jsx("span", {
      className: "Pagination-Nav",
      children: _jsx("button", {
        type: "button",
        onClick: () => this.goToPage(page),
        title: 'Jump to the ' + relative + ' page',
        children: _jsx(Icon, {
          fa: icon
        })
      })
    });
  }
  renderPageList() {
    const {
      innerRadius
    } = settings;
    const {
      currentPage
    } = this.props;
    const totalPages = this.getTotalPages();
    const left = Math.max(1, currentPage - innerRadius);
    const right = Math.min(currentPage + innerRadius, totalPages);
    const pageList = range(left, right + 1);
    return _jsxs("span", {
      className: "Pagination-Nav",
      children: [left > 1 && this.renderPageLink(1, currentPage), left > 2 && this.renderEllipsis(2), pageList.map(page => this.renderPageLink(page, currentPage)), right < totalPages - 1 && this.renderEllipsis(totalPages - 1), right < totalPages && this.renderPageLink(totalPages, currentPage)]
    });
  }
  renderPerPageMenu() {
    const {
      rowsPerPage,
      rowsPerPageOptions,
      onRowsPerPageChange
    } = this.props;
    if (!onRowsPerPageChange) return null;
    return _jsx("span", {
      className: "Pagination-Editor",
      children: _jsx(RowsPerPageMenu, {
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: rowsPerPageOptions,
        onRowsPerPageChange: onRowsPerPageChange
      })
    });
  }
  render() {
    const {
      currentPage,
      rowsPerPage,
      totalRows
    } = this.props;
    const totalPages = this.getTotalPages();
    const PageList = this.renderPageList;
    const PerPageMenu = this.renderPerPageMenu;
    const RelativeLink = this.renderRelativeLink;
    if (!totalPages || !currentPage) return null;
    const showPageList = totalRows > rowsPerPage;
    return _jsxs("div", {
      className: "PaginationMenu",
      children: [_jsx("span", {
        className: "Pagination-Spacer"
      }), showPageList && _jsx(RelativeLink, {
        relative: "previous"
      }), showPageList && _jsx(PageList, {}), showPageList && _jsx(RelativeLink, {
        relative: "next"
      }), _jsx(PerPageMenu, {})]
    });
  }
}
PaginationMenu.propTypes = {
  totalRows: PropTypes.number,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
  onRowsPerPageChange: PropTypes.func
};
export default PaginationMenu;