import React from "react";
import { useMemo } from 'react';
import Icon from '.';
import { gray } from '../../definitions/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export default function Cancel(props) {
  const finalProps = useMemo(() => {
    const defaultProps = {
      width: 13,
      height: 13,
      color: gray[400],
      extraCSS: {}
    };
    return {
      ...defaultProps,
      ...props
    };
  }, [props]);
  return _jsx(Icon, {
    ...finalProps,
    children: _jsx(CancelIcon, {
      htmlColor: finalProps.color
    })
  });
}