import React from 'react';
import IndeterminateCheckbox from '../../inputs/checkboxes/IndeterminateCheckbox';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    let {
      checked,
      onChange
    } = this.props;
    if (typeof onChange === 'function') onChange(!!checked);
  }
  render() {
    let {
      checked,
      className,
      disabled,
      indeterminate = false
    } = this.props;
    className = 'Checkbox' + (className ? ' ' + className : '');
    className += ' ' + (checked ? 'Checkbox-Checked' : 'Checkbox-Unchecked');
    className += disabled ? ' Checkbox-Disabled' : '';
    return _jsx("div", {
      className: className,
      children: indeterminate ? _jsx(IndeterminateCheckbox, {
        checked: checked,
        indeterminate: indeterminate,
        onChange: this.handleClick
      }) : _jsx("input", {
        type: "checkbox",
        checked: checked,
        onChange: this.handleClick
      })
    });
  }
}
export default Checkbox;