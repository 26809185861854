import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
class Icon extends React.PureComponent {
  render() {
    let {
      fa,
      className,
      onClick,
      style
    } = this.props;
    className = `icon fa fa-${fa} ${className || ''}`;
    return _jsx("i", {
      onClick: onClick,
      style: style,
      className: className,
      children: ' '
    });
  }
}
export default Icon;