import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
class OverScroll extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let {
      className,
      height
    } = this.props;
    className = 'OverScroll' + (className ? ' ' + className : '');
    height = typeof height === 'number' ? height + 'px' : 'none';
    const style = {
      maxHeight: height,
      overflowY: 'auto'
    };
    return _jsx("div", {
      className: className,
      children: _jsx("div", {
        className: "OverScroll-Inner",
        style: style,
        children: this.props.children
      })
    });
  }
}
export default OverScroll;